import {IAction} from "@pg-mono/request-state";

export interface ISetBottomFixedElementHeight extends IAction {
    value: number | null;
}

export const SET_BOTTOM_FIXED_ELEMENT_HEIGHT = "ui/SET_BOTTOM_FIXED_ELEMENT_HEIGHT";

export const setBottomFixedElementHeight = (value: number | null) => ({
    type: SET_BOTTOM_FIXED_ELEMENT_HEIGHT,
    value
});

export type IUiActions = ISetBottomFixedElementHeight;
