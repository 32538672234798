import {Dispatch} from "redux";

import {catch5xx, catch400, postRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {userPreferencesApiLink} from "@pg-mono/services-api-routes";

import {IUserPreferences} from "../interfaces/IUserPreferences";
import {fetchUserPreferencesTypes} from "./fetch_user_preferences";

const POST_USER_PREFERENCES_PREFIX = "user/SAVE_PREFERENCES";
export const saveUserPreferencesTypes = createRequestActionTypes(POST_USER_PREFERENCES_PREFIX);

const requestDebounceTime = 2000;

let lastRequestActionId: ReturnType<typeof setTimeout>;
export const postUserPreferences =
    (preferences: IUserPreferences, debounce = false) =>
    async (dispatch: Dispatch) => {
        const url = userPreferencesApiLink.userPreferences.base(null);

        dispatch({type: saveUserPreferencesTypes.start});

        const sendRequest = () =>
            postRequest({}, url, {...preferences})
                .then((result: {data: {filters: Record<string, number | number[]>} | null}) => {
                    if (result) {
                        dispatch({type: saveUserPreferencesTypes.success});
                        dispatch({type: fetchUserPreferencesTypes.start});
                        dispatch({
                            type: fetchUserPreferencesTypes.success,
                            result: result.data
                        });

                        return true;
                    }

                    return false;
                })
                .catch(
                    catch400(() => {
                        dispatch({type: saveUserPreferencesTypes.error});
                        return false;
                    })
                )
                .catch(
                    catch5xx(() => {
                        dispatch({type: saveUserPreferencesTypes.error});
                        return false;
                    })
                );

        const sendRequestWithDebounce = () => {
            if (lastRequestActionId) {
                clearTimeout(lastRequestActionId);
            }

            lastRequestActionId = setTimeout(sendRequest, requestDebounceTime);
        };

        return debounce ? sendRequestWithDebounce() : sendRequest();
    };
