import React from "react";
import {Controller, ControllerProps, useController} from "react-hook-form";

import {Checkbox, ICheckboxProps} from "@pg-design/checkbox";

type IProps<FormValues extends object> = Omit<ControllerProps<FormValues>, "render" | "value"> & {} & Omit<ICheckboxProps, "checked" | "onChange" | "name"> &
    ILabelScenarios;

/*
    Label scenarios - provide aria-labelledby if you're not providing a labelContent
 */
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: JSX.Element | string;
    "aria-labelledby"?: never;
};

export function CheckboxControlled<FormValues extends object>(props: IProps<FormValues>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {name, rules, defaultValue, control, ...inputProps} = props;
    const fieldController = useController<FormValues>({control, name});

    if (!fieldController) {
        throw new Error(
            "CheckboxControlled is not wrapped in FormProvider, in this case, please wrap it with FormProvider or provide an optional 'control' prop"
        );
    }

    const {
        field: {onChange}
    } = fieldController;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field}) => (
                <Checkbox
                    {...field}
                    {...inputProps}
                    checked={field.value}
                    onChange={(fieldName, value) => {
                        onChange(value);
                    }}
                />
            )}
        />
    );
}
