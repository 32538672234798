import {useEffect, useState} from "react";

import {MobileOptionsModal} from "../../atoms/MobileOptionsModal";
import {SortOptions, SortOptionsValues} from "../../offer/detail/components/sort/SortOptions";
import {propertyListSortOptions} from "../constants/property_list_sort_options";

interface IProps {
    onClose: () => void;
    onClear: (value: SortOptionsValues) => void;
    onSave: (value: SortOptionsValues) => void;
    isOpen: boolean;
    defaultValue: SortOptionsValues;
}

export const PropertyListSortOptionsModal = (props: IProps) => {
    const [sortValue, setSortValue] = useState(props.defaultValue);

    useEffect(() => {
        setSortValue(props.defaultValue);
    }, [props.isOpen, props.defaultValue]);

    const handlers = {
        onClear: () => {
            setSortValue(props.defaultValue);
            props.onClear(props.defaultValue);
        },
        onSave: () => {
            props.onSave(sortValue);
        }
    };

    return (
        <MobileOptionsModal modalHeading="Sortuj" isOpen={props.isOpen} onModalClose={props.onClose}>
            <SortOptions
                currentValue={sortValue}
                setCurrentValue={setSortValue}
                sortOptions={propertyListSortOptions}
                onCancel={handlers.onClear}
                onSave={handlers.onSave}
            />
        </MobileOptionsModal>
    );
};
