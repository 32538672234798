import React from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button";
import {calculateRemSize, flexAbsoluteCenter, flexDirection, mb, w75} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {ModalCore} from "@pg-design/modal";
import {Highlight, Text} from "@pg-design/text";
import {phoneNumberFormat, priceFormat} from "@pg-mono/string-utils";

import {ApplicationVendorInfo} from "../../application/components/ApplicationVendorInfo";
import {RodoFormInfo} from "../../application/components/RodoFormInfo";
import {IApplicationVendor} from "../../application/types/IApplicationVendor";

interface IProps {
    isOpen: boolean;
    closeModal: () => void;
    vendor: {
        name: string;
        logo: {
            v_log_120x90: string;
        } | null;
    };
    offer: {
        name: string;
        configuration: {
            personal_data_processor_name?: string | null;
            personal_data_processor_url?: string | null;
            privacy_policy_url?: string | null;
        };
    };

    propertyPrice?: number | null;
    phoneNumber?: string;
    trackUser: () => void;
}
export const CallModal = (props: IProps) => {
    const {isOpen, closeModal, vendor, offer, propertyPrice, phoneNumber, trackUser} = props;

    return (
        <ModalCore isOpen={isOpen} overlayStyle={modalOverlay} contentStyle={modalContent}>
            <CloseIcon css={closeIcon} size="2" wrapperSize="3.2" wrapperColor="white" onClick={closeModal} />
            <div css={contentWrapper}>
                <ApplicationVendorInfo vendorLogo={vendor.logo && vendor.logo.v_log_120x90} vendorName={vendor.name} headerText={offer.name} />

                <Button
                    variant="filled_primary"
                    href={!!phoneNumber ? `tel:+${phoneNumber.replace(/ /g, "")}` : undefined}
                    onClick={trackUser}
                    css={callButton}
                >
                    {!!phoneNumber ? phoneNumberFormat(phoneNumber) : "BRAK DANYCH KONTAKTOWYCH"}
                </Button>

                {propertyPrice && (
                    <>
                        <Text as="span" variant="body_copy_2" css={mb(1)}>
                            Cena tej nieruchomości:
                        </Text>
                        <Text as="span" variant="body_copy_0" align="center" css={mb(2)}>
                            <Highlight strong>{priceFormat(propertyPrice as number)}</Highlight>
                        </Text>
                    </>
                )}

                <RodoFormInfo
                    vendor={vendor as IApplicationVendor}
                    offerPrivacyPolicyUrl={offer?.configuration.privacy_policy_url}
                    offerPersonalDataProcessorName={offer?.configuration.personal_data_processor_name as string}
                    offerPersonalDataProcessorUrl={offer?.configuration.personal_data_processor_url}
                />
            </div>
        </ModalCore>
    );
};

const modalOverlay = css`
    //z-index is greater by 1 than the z-index of the gallery on offer/property view
    z-index: 9001;
`;

const modalContent = css`
    inset: auto ${calculateRemSize(1.5)} ${calculateRemSize(8)} ${calculateRemSize(1.5)};
    overflow: visible;
`;

const closeIcon = css`
    position: absolute;
    z-index: 9002;
    top: -28px;
    right: -28px;
`;

const contentWrapper = css`
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
`;

const callButton = css`
    ${mb(2)};
    ${w75};
    max-width: 360px;
`;
