import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {getTrackedOfferData, ITrackedOffer} from "../tracked_utils/tracked_offer";
import {getTrackedSiteData} from "../tracked_utils/tracked_site";
import {getTrackedVendorData, ITrackedVendor} from "../tracked_utils/tracked_vendor";

export enum ShowPropertyPlanView {
    PROPERTY_DETAIL = "property_detail",
    PROPERTY_LIST = "property_list",
    PLAN_IMAGE_DOWNLOAD = "plan_image_download",
    PLAN_DEFAULT = "plan_default"
}

const showPropertyPlanClickAlgolytics = delayHit((payload) => hitAlgolytics("property_plan", payload), 500);

export const showPropertyPlanView = (viewType: ShowPropertyPlanView, offer: ITrackedOffer, vendor: ITrackedVendor) => {
    const payload = {
        view_type: viewType,
        ...getTrackedSiteData(),
        ...getTrackedVendorData(vendor),
        ...getTrackedOfferData(offer)
    };
    showPropertyPlanClickAlgolytics(payload);
};
