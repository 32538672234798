import {IUserPreferences} from "../interfaces/IUserPreferences";

export const transformUserPreferencesForSave = (preferences: IUserPreferences & {construction_end_date?: string | string[]}): IUserPreferences => {
    return {
        filters: {
            ...preferences.filters,
            ...(preferences.filters?.construction_end_date
                ? {
                      construction_end_date: Array.isArray(preferences.filters?.construction_end_date)
                          ? preferences.filters?.construction_end_date[0]
                          : preferences.filters?.construction_end_date
                  }
                : {})
        }
    };
};
