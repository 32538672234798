import styled from "@emotion/styled";

import {ph, pv} from "@pg-design/helpers-css";

interface IProps {
    backgroundColor?: string;
}

export const BottomBar = styled.div<IProps>`
    ${pv(1)};
    ${ph(2)};
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "#fff")};
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        display: none;
    }
`;
