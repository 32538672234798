import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const SortIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="m5.4 3.354.754.755.377-.378L5.4 2.601 4.269 3.73l.377.378.754-.755ZM4.646 5.891l.754.755.754-.755.377.378L5.4 7.4 4.269 6.27l.377-.379Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.6 1h-.4A2.2 2.2 0 0 0 3 3.2v3.6A2.2 2.2 0 0 0 5.2 9h.4a2.2 2.2 0 0 0 2.2-2.2V3.2A2.2 2.2 0 0 0 5.6 1Zm-.249.533h.1a1.817 1.817 0 0 1 1.813 1.818v1.382H3.533V3.351a1.818 1.818 0 0 1 1.818-1.818Zm.096 6.934h-.096a1.818 1.818 0 0 1-1.818-1.818V5.267h3.731v1.382a1.817 1.817 0 0 1-1.817 1.818Z"
            />
        </SvgIcon>
    );
};
