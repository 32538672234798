// NOTE: the same event name was used for two different actions.
// From august of 2017 for call action and from august of 2019 for show full number action.
// We need to differ between those two, so the easiest way is to add additional type.
import {phoneNumberClickAlgolytics} from "@pg-mono/algolytics";

import {getTrackedOfferData, ITrackedOffer} from "../tracked_utils/tracked_offer";
import {getTrackedPropertyData, ITrackedProperty} from "../tracked_utils/tracked_property";
import {getTrackedSiteData} from "../tracked_utils/tracked_site";
import {getTrackedVendorData, ITrackedVendor} from "../tracked_utils/tracked_vendor";

export const vendorViewPhoneNumberClick = (vendor: ITrackedVendor) => {
    const payload = {
        event_type: "call",
        view_type: "vendor",
        ...getTrackedSiteData(),
        ...getTrackedVendorData(vendor)
    };
    phoneNumberClickAlgolytics(payload);
};

export const offerViewPhoneNumberClick = (offer: ITrackedOffer, vendor: ITrackedVendor) => {
    const payload = {
        event_type: "call",
        view_type: "offer",
        ...getTrackedSiteData(),
        ...getTrackedVendorData(vendor),
        ...getTrackedOfferData(offer)
    };
    phoneNumberClickAlgolytics(payload);
};

// export const opendaysViewPhoneNumberClick = (offer: ITrackedOffer, vendor: ITrackedVendor) => {
//     const payload = {
//         event_type: "call",
//         view_type: "opendays",
//         ...getTrackedMobileSiteData(),
//         ...getTrackedVendorData(vendor),
//         ...getTrackedOfferData(offer)
//     };
//     phoneNumberClickAlgolytics(payload);
// };

export const propertyViewPhoneNumberClick = (property: ITrackedProperty, offer: ITrackedOffer, vendor: ITrackedVendor) => {
    const payload = {
        event_type: "call",
        view_type: "property",
        ...getTrackedSiteData(),
        ...getTrackedVendorData(vendor),
        ...getTrackedOfferData(offer),
        ...getTrackedPropertyData(property)
    };
    phoneNumberClickAlgolytics(payload);
};
