import {Theme} from "@emotion/react";

import {ISimhomeAreaType, ISimhomeRoom} from "../types/isimhome_property";
import {getRpAreaType} from "./get_rp_area_type";
import {getSimhomeRoomTypeName} from "./get_simhome_room_type";

export function getRpAreaColor(areaType: ISimhomeAreaType, roomType: ISimhomeRoom["room_type"], selected: boolean, theme: Theme) {
    const simhomeRoomType = getSimhomeRoomTypeName(roomType);
    const rpAreaType = getRpAreaType(areaType, simhomeRoomType);

    if (selected) {
        switch (rpAreaType) {
            case "garden":
                return theme.colors.success;
            case "room":
                return theme.colors.warning;
            case "bathroom":
                return theme.colors.info;
            case "kitchen":
                return theme.colors.primary;
            default:
                return theme.colors.background[200];
        }
    }

    switch (rpAreaType) {
        case "garden":
            return "#E8F2E1";
        case "room":
            return "#FAEDE3";
        case "bathroom":
            return "#E1F0FA";
        case "kitchen":
            return "#F0E6F2";
        default:
            return "#F2F0ED";
    }
}
