import {useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize as crs, mb, onDesktop, p, pv} from "@pg-design/helpers-css";
import {BrandWindRoseIcon} from "@pg-design/icons";
import {Image, Placeholder} from "@pg-design/image";
import {Text} from "@pg-design/text";
import {pluralize} from "@pg-mono/string-utils";

import {IPropertyDetail, IPropertyDetailVendor} from "../../interfaces/IPropertyDetail";
import {ISimhomeAreaType, ISimhomeProperty} from "../../types/isimhome_property";
import {getPropertyFloorTextData, IPropertyWithFloorInfo} from "../../utils/get_property_floor_text_data";
import {PropertyFloorImage} from "./PropertyFloorImage";
import {PropertyPlanAreaList} from "./PropertyPlanAreaList";
import {PropertyPlanImage} from "./PropertyPlanImage";
import {PropertyPlanWallsLegend} from "./PropertyPlanWallsLegend";

interface IProps {
    vendor: IPropertyPlanVendor;
    property: IPropertyPlanProperty;
    simhomeProperty: ISimhomeProperty;
}

export type IPropertyPlanVendor = Pick<IPropertyDetailVendor, "logo" | "name">;
export type IPropertyPlanProperty = Pick<IPropertyDetail, "number" | "rooms" | "area" | "plan"> & {offer: IPropertyPlanOffer} & {
    uuid: string;
} & IPropertyWithFloorInfo;
type IPropertyPlanOffer = Pick<IPropertyDetail["offer"], "address">;

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export function PropertyPlan(props: IProps) {
    const {property, vendor, simhomeProperty} = props;

    const [selectedArea, setSelectedArea] = useState<string | null>(null);

    const theme = useTheme();

    const rooms = simhomeProperty.rooms;
    const floorTextData = getPropertyFloorTextData(property);
    const includedRooms = rooms.filter(({area_type}) => area_type === ISimhomeAreaType.LIVING_SPACE || area_type === ISimhomeAreaType.USABLE_AREA);
    const excludedRooms = rooms.filter((room) => room.area_type === ISimhomeAreaType.ADDITIONAL_AREA);
    const planSvgUrl = simhomeProperty.plan_preview_urls[0][1];
    const floorSvgUrl = simhomeProperty.floor_preview_urls[0][property.floor];

    const handleSelectArea = (areaId: string | null) => {
        setSelectedArea(areaId);
    };

    return (
        <div css={propertyPlan}>
            <div css={planImageSection}>
                <div css={[propertyHeader, mb(1.5)]}>
                    <div css={propertyTitle}>
                        <Text variant="headline_4">
                            <span css={nonBrakingText}>Mieszkanie {property.number},</span>{" "}
                            <span css={nonBrakingText}>
                                <span css={redColor}>{property.rooms}</span> {roomsPluralize(property.rooms)},
                            </span>{" "}
                            <span css={nonBrakingText}>
                                {floorTextData.number !== 0 && <span css={redColor}>{floorTextData.number}</span>} {floorTextData.text}
                            </span>
                        </Text>
                        <Text color={theme.colors.gray[700]}>
                            {vendor.name}, {property.offer.address}
                        </Text>
                    </div>
                    {simhomeProperty.wind_rose_rotation ? (
                        <WindRoseWrap rotation={simhomeProperty.wind_rose_rotation}>
                            <BrandWindRoseIcon size="7.2" wrapperSize="7.2" wrapperColor="transparent" />
                        </WindRoseWrap>
                    ) : null}
                </div>
                <div css={[mb(3), propertyPlanImageWrap]}>
                    {planSvgUrl ? (
                        <PropertyPlanImage
                            css={propertyPlanImage}
                            planSvgUrl={planSvgUrl}
                            selectedAreaId={selectedArea}
                            handleSelectArea={handleSelectArea}
                            propertyRooms={rooms}
                        />
                    ) : (
                        <div css={planPlaceholderWrap}>
                            <Placeholder />
                        </div>
                    )}
                </div>
                <div>
                    <PropertyPlanWallsLegend />
                </div>
            </div>

            <div css={planDataSection}>
                <div css={vendorLogoWrap}>
                    <Image width="106.67px" height="80px" src={vendor.logo?.v_log_120x90} alt={vendor.name} />
                </div>
                <div>
                    <PropertyPlanAreaList rooms={includedRooms} selectedAreaId={selectedArea} handleSelectArea={handleSelectArea} />
                    <Text variant="body_copy_1" as="span" css={[redColor, areaSummary]}>
                        <strong>{property.area} m2</strong>
                    </Text>
                    <PropertyPlanAreaList
                        rooms={excludedRooms}
                        selectedAreaId={selectedArea}
                        handleSelectArea={handleSelectArea}
                        lastAreaIndex={includedRooms.length}
                    />
                </div>
                <div css={propertyFloorImageWrap}>
                    {floorSvgUrl && <PropertyFloorImage css={propertyFloorImage} propertyUuid={property.uuid} floorSvgUrl={floorSvgUrl} />}
                    {/**
                     * TODO: Based on figma projects, we should have a floor placeholder, however, for now, too many properties are missing floors, so
                     *  placeholder is turned off instead. When more properties has floor, check again if we should have it back or remove it permanently
                     *  from here.
                     * <div css={floorPlaceholderWrap}>
                     *    <Placeholder />
                     * </div>
                     */}
                </div>
                <div>
                    <Text variant="info_txt_3" color={theme.colors.gray[700]}>
                        Podane powierzchnie mogą ulec niewielkim zmianom wynikającym z realizacji projektu. Więcej informacji uzyskasz po kontakcie z
                        deweloperem.
                    </Text>
                </div>
            </div>
        </div>
    );
}

//  Styles
const redColor = (theme: Theme) => css`
    color: ${theme.colors.danger};
`;

const propertyPlan = css`
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: ${crs(3)};
    background: white;
    ${p(2, 1.5, 3, 1.5)};
    overflow: hidden;

    ${onDesktop(css`
        flex-direction: row;
        ${p(0)}
    `)}
`;

const planImageSection = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${crs(2)};

    ${onDesktop(css`
        border-right: 1px solid ${theme.colors.gray[300]};
        ${p(2, 3)}
        flex: 2 1 auto;
    `)}
`;

const propertyHeader = css`
    display: flex;
    column-gap: ${crs(0.5)};
    justify-content: space-between;
    align-items: center;
`;

const propertyTitle = css`
    display: flex;
    flex-direction: column;
    row-gap: ${crs(0.5)};
`;

const propertyPlanImageWrap = css`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const propertyPlanImage = css`
    max-height: 286px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${onDesktop(css`
        max-height: 600px;
    `)}
`;

const planDataSection = css`
    display: flex;
    flex-direction: column;
    row-gap: ${crs(3)};

    ${onDesktop(css`
        row-gap: ${crs(4)};
        ${p(2)};
        flex: 1 1 465px;
    `)}
`;

const vendorLogoWrap = css`
    display: flex;
    justify-content: flex-end;
`;

const propertyFloorImageWrap = css`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const propertyFloorImage = css`
    overflow: hidden;
    max-height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const areaSummary = (theme: Theme) => css`
    display: flex;
    ${pv(1.5)};
    justify-content: flex-end;
    border-bottom: 1px solid ${theme.colors.gray[200]};
`;

const WindRoseWrap = styled.div<{rotation: number}>`
    ${({rotation}) => css`
        transform: rotate(${rotation}deg);
    `}
`;

const planPlaceholderWrap = css`
    height: 714px;
    position: relative;
`;

const nonBrakingText = css`
    white-space: nowrap;
`;

//  TODO: Might be needed again, check todo above
// const floorPlaceholderWrap = css`
//     height: 160px;
//     position: relative;
// `;
