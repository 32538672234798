import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {fetchOsmPoisRoutesGrouped} from "../actions/fetch_osm_pois_routes_grouped";
import {IPoiStore} from "../reducers/poi_reducer";

export const useOsmPoisRoutesGrouped = (): IPoiStore["poisRoutesGrouped"] | null => {
    const requestedArea = useSelector((state: IRPStore) => state.maps.poi.requestedArea);
    const poisRoutes = useSelector((state: IRPStore) => state.maps.poi.poisRoutesGrouped);
    const poisRoutesRequest = useSelector((state: IRPStore) => state.maps.poi.poisRoutesGroupedRequest);
    const dispatch = useDispatch();

    useEffect(() => {
        if (poisRoutesRequest || !requestedArea?.latitude || !requestedArea?.longitude) {
            return;
        }

        dispatch(fetchOsmPoisRoutesGrouped(requestedArea));
    }, [requestedArea, poisRoutesRequest]);

    return poisRoutes;
};
