export interface ISimhomeProperty {
    plan_preview_urls: {[index: string]: string | null}[];
    is_ready: boolean;
    floor_preview_urls: {[index: string]: string | null}[];
    rooms: ISimhomeRoom[];
    wind_rose: null;
    wind_rose_rotation: number | null;
    area_verified: boolean;
}

export interface ISimhomeRoom {
    name: string;
    name_pl: string;
    area: number;
    area_type: ISimhomeAreaType;
    exposure: string[] | null;
    sunlight_percentage: null | number;
    planner_id: string;
    level: number;
    room_type: ISimhomeRoomType;
}

export enum ISimhomeAreaType {
    LIVING_SPACE = 0,
    USABLE_AREA = 1,
    ADDITIONAL_AREA = 2,
    EXCLUDED_AREA = 3
}

export type ISimhomeRoomType = number | null;
