import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PoiTramIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.642 2.6 6.566 1H3.247l.308.533L4.17 2.6H2.5v5.333h1.067V9H4.1V7.933h1.6V9h.533V7.933H7.34V2.6H5.642ZM4.17 1.533h1.472L5.026 2.6h-.24L4.17 1.533Zm-1.137 1.6h1.589v2.134H3.033V3.133ZM6.806 7.4H3.033V5.8h3.773v1.6Zm0-2.133h-1.65V3.133h1.65v2.134Z" />
            <path d="M4.1 6.333h-.533v.533H4.1v-.533ZM6.233 6.333H5.7v.533h.533v-.533Z" />
        </SvgIcon>
    );
};
