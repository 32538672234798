import {passiveTransportMarkerUrls} from "../constants/transport_marker_urls";
import {IPublicTransportRouteWithStops} from "../types/IPublicTransportRouteWithStops";

const publicTransportStopPin = require("../images/pins/public_transport_stop_pin.svg");

export function getPassiveTransportStopIconUrl(type?: IPublicTransportRouteWithStops["type"]): string {
    if (type) {
        return passiveTransportMarkerUrls[type];
    }

    return publicTransportStopPin;
}
