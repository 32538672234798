import {Dispatch} from "redux";

import {appendQueryString, getExternalRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestState} from "@pg-mono/request-state";

import {osmApiUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {OSMPoiRoutesUrl} from "../constants/osm_api_routes";
import {IPoiAround} from "../types/IPoiAround";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {IPublicTransportType} from "../types/IPublicTransportType";

const FETCH_POI_ROUTES_GROUPED = "overpass/FETCH_POI_ROUTES_GROUPED";
export const fetchPoisRoutesGroupedTypes = createRequestActionTypes(FETCH_POI_ROUTES_GROUPED);

export type IOSMPoisRoutesGrouped = Partial<{
    [key in IPublicTransportType]: IPublicTransportRoute[];
}>;

const splitRoutes = (osmPoisRoutes: IOSMPoisRoutesGrouped) =>
    Object.entries(osmPoisRoutes).reduce((routesNormalized, [group, rawRoutes]: [string, IPublicTransportRoute[]]) => {
        routesNormalized[group as keyof IOSMPoisRoutesGrouped] = rawRoutes.reduce((finalRouteList, route) => {
            finalRouteList.push({
                ...route,
                type: group as keyof IOSMPoisRoutesGrouped
            });

            return finalRouteList;
        }, [] as IPublicTransportRoute[]);

        return routesNormalized;
    }, {} as IOSMPoisRoutesGrouped);

export const fetchOsmPoisRoutesGrouped = (params: IPoiAround) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    if (osmApiUrl === null) {
        throw new Error("env: OSM_API_URL is not defined");
    }

    if (getState().maps.poi.poisRoutesGroupedRequest !== RequestState.None) {
        return;
    }

    const url = appendQueryString(OSMPoiRoutesUrl, {
        point: `${params.latitude},${params.longitude}`,
        dist: params.radius
    });

    dispatch({type: fetchPoisRoutesGroupedTypes.start});

    return getExternalRequest({}, url, 5000)
        .then((routes: IOSMPoisRoutesGrouped) => {
            const result = splitRoutes(routes);

            dispatch({type: fetchPoisRoutesGroupedTypes.success, result});
            return result;
        })
        .catch(() => {
            dispatch({type: fetchPoisRoutesGroupedTypes.error});
            return {};
        });
};
