import {css, Theme} from "@emotion/react";

export const linkPseudoStateUnStyled = css`
    &:link,
    &:visited,
    &:hover,
    &:active {
        text-decoration: none;
        color: inherit;
    }
`;

export const asLink = (theme: Theme) => css`
    color: ${theme.colors.secondary};
    text-decoration: none;

    &:hover,
    &:focus {
        color: ${theme.colors.highlight};
        text-decoration: underline;
    }
`;
