import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PoiSubwayIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M2.37 6.764a3.37 3.37 0 0 1-.188-.007c.013-.002.015-.013.015-.085 0-.05.01-.146.026-.244.034-.216.033-.209.052-.209.016 0 .017-.007.017-.102 0-.141.048-.443.07-.443.015 0 .016-.01.016-.091 0-.061.009-.144.026-.249.034-.203.032-.195.052-.195.016 0 .017-.006.017-.106 0-.125.047-.439.066-.439.007 0 .012-.025.016-.084a3.88 3.88 0 0 1 .033-.265c.023-.152.03-.181.043-.185.013-.003.015-.015.015-.09 0-.056.01-.142.026-.243.034-.205.034-.205.048-.205.008 0 .012-.024.016-.093.007-.132.057-.443.07-.443.008 0 .013-.026.017-.092.005-.08.028-.25.045-.333l.004-.024h1.114v.12c0 .066-.002.127-.005.134-.004.01-.017.014-.043.014H3.9v.228c0 .125-.003.246-.006.268l-.005.04h-.076v.213c0 .117-.004.238-.01.268l-.009.055h-.075l-.003.252c-.002.14-.007.262-.01.273-.006.015-.014.02-.04.02h-.034v.202c0 .111-.004.223-.01.248-.004.026-.01.055-.013.066-.003.016-.01.02-.038.02h-.034v.2c0 .111-.005.23-.01.268l-.01.067H3.45l-.003.214a2.395 2.395 0 0 1-.015.268c-.01.053-.011.054-.04.054h-.028l-.003.192a1.558 1.558 0 0 1-.02.272l-.015.08h-.058v.2L2.92 6.77c-.192 0-.44-.004-.551-.006Z"
                fill="#DC251B"
            />
            <path d="M9 5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#FCF604" />
            <path d="m3.334 6.823-1.225-.01.726-4.365 1.252.025-.753 4.35ZM6.713 6.853l1.225-.011-.726-4.365-1.252.025.753 4.35Z" fill="#DC251B" />
            <path d="m5.107 5.015.865-2.533.406 2.395-.389.726-.57.155-.312-.743Z" fill="#DC251B" />
            <path d="m4.18 2.877-.397 2.384.403 1.143-.709.293 1.81 1.027.751-1.907-.727.153-1.13-3.093Z" fill="#DC251B" />
        </SvgIcon>
    );
};
