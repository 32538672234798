import {useEffect, useRef, useState} from "react";

export const useBoundingClientRect = <T extends HTMLElement>(watcher: unknown) => {
    const elementRef = useRef<null | T>(null);

    const [boundingClientRect, setBoundingClientRect] = useState<DOMRect | null>(null);

    useEffect(() => {
        if (elementRef.current) {
            setBoundingClientRect(elementRef.current.getBoundingClientRect());
        }
    }, [elementRef.current, watcher]);

    return {
        elementRef,
        boundingClientRect
    };
};
